@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

.App {
  text-align: center;
  background-color: #06002a;
  align-items: center;
  justify-content: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

a {
  text-decoration: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(198, 83, 83);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes slideDown {
  from {
    transform: translateY(-100%); /* Start from above the top of the viewport */
  }
  to {
    transform: translateY(0); /* End at the navbar's natural position */
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px); /* Start 20px below the final position */
  }
  to {
    opacity: 1;
    transform: translateY(0); /* End at the final position */
  }
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.testimonial-slide-right {
  animation: slideInFromRight 0.5s forwards;
}

.testimonial-slide-left {
  animation: slideInFromLeft 0.5s forwards;
}

body {
  background-color: #ffffff; /* Set the background color for the entire website */
  margin: 0; /* Remove default body margin */
  padding: 0; /* Remove default body padding */
  font-family: 'Poppins', sans-serif;
  overflow-x: hidden;
}

.main-content {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  transition: background-color 0.3s ease;
  backdrop-filter: blur(5px);
  /* background-color: white; */
  display: flex;
  justify-content: space-between; /* Aligns all items on the main axis to the center */
  align-items: center;
  animation: slideDown 0.5s ease forwards;
  padding: 10px;
}

.footer {
  background-color: #1F2F34;
  width: 100vw;
  padding: 2rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
}

.footer-logo img {
  width: 13rem;
}

.footer-header {
  color: white;
  font-size: 20px;
}

.footer-item {
  color: white;
  font-size: 16px;
  text-decoration: none;
  margin-bottom: 2px;
}

.footer-item:hover {
  cursor: pointer;
}

.footer-items {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  text-align: start;
  gap: 2px;
}

.footer-info-item {
  display: flex;
  justify-content: start;
  align-items: flex-start;
  gap: 6px;
  margin-bottom: 4px;
}

.footer-info-item img {
  align-self: flex-start; 
  margin-top: 3px;
}

.footer-info-item p {
  color: white;
  font-size: 16px;
  margin: 0;
}

.horizontal-stretched {
  display: flex;
  align-items: start;
  justify-content: space-around;
  width: 90vw;
  max-width: 1680px;
  overflow-x: hidden;
}

.social-media-footer:hover {
  cursor: pointer;
}

.white-text {
  color: white;
}

p {
  color: black;
  position: relative;
}

h4, h3, h2, p {
  font-family: Poppins;
}

.center {
  text-align: center;
  align-items: center;
  justify-content: center;
}

.titles{
  align-items: flex-start;
  justify-content: start;
}

.home-body1{
  top: 57px;
  left: -387px;
}

.home-body2{
  top: 29px;
  left: -406px;
}

.home-body3{
  top: 0px;
  left: -478px;
}

.nav-item-right {
  margin-right: 3.5rem;
}

.logo img {
  height: auto;
  width: 80%;
  cursor: pointer;
}

.nav-brand {
  justify-content: center; /* Centers the logo horizontally */
  display: flex;
}

/* Targets links inside nav-item-left and nav-item-right specifically */
.nav-item-left a, .nav-item-right a {
  color: rgb(7, 17, 89);
  font-weight: 500;
  text-decoration: none;
  margin: 0 40px; /* Adjusts spacing between the items */
  padding: 10px 0;
  transition: color 0.3s ease;
}

/* Adds a hover effect */
.nav-item-left a:hover, .nav-item-right a:hover {
  color: #ccc;
  text-decoration: none;
}

.nav-list {
  list-style-type: none;
  display: flex;
  justify-content: center; /* Or use space-between based on your preference */
  align-items: center; /* Keeps items vertically centered */
  padding: 0; /* Remove default padding */
  width: 100%; /* Ensures the list takes full width of its container */
}

.nav-list li {
  margin: 0 100px; /* Adds horizontal margin to each list item */
}

.nav-list li a {
  display: block;
  color: #3291a9;
  font-size: large;
  text-align: center;
  padding: 10px 12px;
  text-decoration: none;
  font-weight: bold;
  opacity: 70%;
  transition: background-color 0.3s ease, color 0.3s ease, font-size 0.3s ease; /* Add transition effect */
}

.nav-list li a:hover {
  opacity: 100%;
  color: rgb(254, 254, 255); /* White radiance effect for text */
  font-size: 18px; /* Adjust the desired font size */
}

.book-now {
  display: inline-block; /* Allows width and height to be set */
  padding: 0.5rem 1.25rem;
  border-radius: 30px; /* Rounded corners */
  background-color: #2AA0C5; /* A shade of blue */
  color: white; /* Text color */
  text-decoration: none; /* Removes underline */
  transition: background-color 0.3s ease; /* Smooth background color transition */
  border: none; /* NEW - remove default button border */
  outline: none; /* NEW - remove focus outline */
  cursor: pointer; /* NEW - ensure it's clear this is clickable */
  text-align: center; /* NEW - ensure text is centered */
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  font-size: 20px;
}

.book-now:hover {
  background-color: #2588A7; /* Darker shade of blue on hover */
}

.book-now-large {
  display: inline-block; 
  padding: 0.75rem 2rem;
  border-radius: 30px; 
  background-color: #2AA0C5; 
  color: white; 
  text-decoration: none; 
  transition: background-color 0.3s ease; 
  border: none;
  outline: none; 
  cursor: pointer; 
  text-align: center; 
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  font-size: 24px;
  font-weight: 400;
  display: flex;
  gap: 5px;
}

.book-now-large:hover {
  background-color: #2588A7; /* Darker shade of blue on hover */
}


.book-now-text {
  margin: 10px;
  font-weight: bold;
}

/* App.css or your custom CSS file */
.home-background {
  position: relative;
  align-items: center;
  justify-content: center;
  text-align: center;
  color:white;
  font-family: 'Gilroy', sans-serif; 
  font-size: 50px;
  height: 100%;
  width: 100%;
  gap: 1px;
  overflow-x: hidden;
}

.hidden {
  opacity: 0;
  transform: translateY(20px); /* Move elements down by 20px initially */
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.initial-hidden .hidden {
  opacity: 0;
  transform: translateY(20px); /* Keep the transformed state */
  visibility: hidden; /* Ensures elements are not visible */
}

.start-animation .hidden {
  animation: fadeIn 1s ease forwards;
  visibility: visible;
}

.show {
  opacity: 1;
  transform: translateY(0); /* Reset the position */
}

.logo {
  height: auto;
  width: 12%;
  max-width: 250px;
  margin-left: 1.5rem;
}

.logo-image {
  height: auto;
  width: 10%;
}

.title {
  height: auto;
  width: 20%;
}

.bookText{
  font-family: Poppins;
  font-size: 30px;
}

.bottom-book-block{
  background-color: #2AA0C5;
}

.centered-container-no-height {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  font-family: 'Poppins', sans-serif;
}

.centered-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 85vh;
  width: 100vw;
  font-family: 'Poppins', sans-serif;
}

.blue {
  background-color: #2AA0C5;
}

.dark-blue {
  background-color: #012D61;
}


.rectangle-fw {
  width: 100vw;
  padding-top: 2rem;
  padding-bottom: 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
  position: relative;
  height: 40vh;
}

.testimonial-wrapper {
  height: 100%;
}

.button-wrapper {
  position: relative;
  margin-top: auto;
  width: 100%;
  display: flex;
  justify-content: center;
}

.top-padding-1 {
  padding-top: 1rem;
}

.top-padding-3 {
  padding-top: 3rem;
}

.bot-padding-12 {
  padding-bottom: 12rem;
}

.vertical-top-padding {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
}

.vertical-bottom-padding {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 3rem;
}

.vertical-start {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  text-align: start;
}

.gap-1 {
  gap: 1rem;
}

.vertical-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.horizontal-start {
  display: flex;
  justify-content: start;
  align-items: center;
  text-align: start;
  gap: 20px;
}

.horizontal {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
  max-width: 1440px;
}

.less-gap-1 {
  gap: 0px;
  margin-bottom: 0.5rem;
}

.less-gap-2 {
  gap: 0px;
  margin-bottom: 1rem;
}

.more-gap {
  gap: 3.5rem;
}

.no-top-margin {
  margin-top: 0;
}

.top-margin-1 {
  margin-top: 2.5rem;
}

.top-margin-3 {
  margin-top: 5.5rem;
}

.bottom-marging-1 {
  margin-bottom: 7rem;
}

.home-text {
  justify-content: start;
  align-items: start;
  text-align: start;
  font-weight: 600;
  color: black;
  font-size: 48px;
}

.book-description{
  margin-top: 200px;
  font-size: 50px;
  font-family: 'Poppins', sans-serif;
  color: black;
  margin-bottom: -18px
}

.book-description2{
  font-size: 40px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  margin-top: 60px;
  color: black;
  margin-bottom: -66px;
}

.accordion-button{
  background-color: rgb(207, 207, 207);
  height: 86%;
  width: 93%;
  border: none;
  border-radius: 22px;
  padding: 12px;
}

.accordion-item{
  font-size: small;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
}

.accordion-content{
  font-size: 11px;
  text-align: center;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
}
.accordion-content.expanded {
  max-height: 1000px; /* Adjust to a large enough value to cover the height of your content */
}

.card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.sub-text {
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 21px;
  font-weight: 300;
  width: 40vw;
}

.treatment-description {
  justify-content: center;
  align-items: center;
  text-align: start;
  font-size: 19px;
  font-weight: 300;
  width: 70vw;
}


.cupping-therapy-image {
  margin-top: 5rem;
  margin-bottom: 4rem;
  border-radius: 15px;
  width: 60%;
  object-fit: cover;
}

.home-descriptions {
  justify-content: start;
  align-items: start;
  text-align: start;
  font-size: 22px;
  font-weight: 300;
}

.small-text {
  font-size: 16px;
  font-weight: 300;
  top: 40px;
  line-height: 0px;
}

/* Remove the bottom margin from the last item to avoid extra space at the bottom */
.centered-container > *:last-child {
  margin-bottom: 0;
}
.home-description2,
.home-description {
  font-size: 50px;
  font-family: 'Poppins', sans-serif;
  color: black;
}

.packages-text{
  font-size: 15px;
  font-family: 'Poppins', sans-serif;
  padding-top: 5%;
  text-align: justify;
  font-weight: bold;
}


.images {
  width: 100%;
}

.home-image {
  height: auto;
  width: 15%;
  border-radius: 10px;
  margin: 0 50px;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.5); /* Example shadow */
}

.plus-imagetop {
  position: absolute;
  z-index: -1;
}

.plus-imagebot {
  position: absolute;
  z-index: -1;
}

.dots {
  position: absolute;
  z-index: -1;
}

.group-dots {
  position: absolute;
  z-index: -1;
}

.dots {
  bottom: 20%;
  right: 26%;
}

.plus-imagetop{
  right: 15%; /* Adjust left position if needed */
  top: 15%;
}

.plus-imagebot {
  left: 5%; 
  bottom: 10%
}

.group-dots {
  left: 20%;
}

.overlay {
  position: absolute;
  top: 38%;
  left: 51%;
  width: 30%;
  height: 118%;
  background-color: #93ebff;
  z-index: 0;
  border-radius: 50%;
  opacity: 7%;
}

.overlay2 {
  position: absolute;
  top: 29%;
  left: 68%;
  width: 20%;
  height: 75%;
  background-color: #93ebff;
  z-index: 0;
  border-radius: 50%;
  opacity: 6%;
}

.learn-more {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.learn-more:hover p {
  cursor: pointer;
  color: #336474;
}

.learn-more:hover .circle {
  cursor: pointer;
  background-color: #336474; /* New background color for the circle on hover */
}

.learn-more p {
  font-weight: 600;
  font-size: 24px;
  color: #529FB7;
  transition: color 0.3s; /* Smooth transition for text color */
}

.circle {
  border-radius: 50%;
  background-color: #529FB7;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  transition: background-color 0.3s; /* Smooth transition for background color */
}

.testimonial-title {
  font-size: 45px;
  font-weight: 800;
  color: white;
}

.testimonial-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 1rem;
}

.testimonial-text, .testimonial-name {
  width: 75%;
  max-width: 1440px;
  padding: 0 3rem;
  margin: 0 auto; /* Centering horizontally */
}

.testimonial-text {
  color: white;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
}

.testimonial-name {
  color: white;
  font-size: 26px;
  font-weight: 500;
  text-align: center;
}
.small-button {
  border-radius: 15px;
  background-color: #69B8D1;
  padding: 0 1.5rem;
  transition: background-color 0.3s ease; 
}

.small-button:hover {
  cursor: pointer;
  background-color: #336474;
}

.book-now-button {
  display: inline-block; /* Allows width and height to be set */
  padding-left: 18px;
  padding-right: 18px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin: 40px;
  border-radius: 30px; /* Rounded corners */
  background-color: #529FB7; /* A shade of blue */
  color: white; /* Text color */
  text-decoration: none; /* Removes underline */
  transition: background-color 0.3s ease; /* Smooth background color transition */
  font-size: 25px;
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.3);
}

.book-now-button:hover {
  background-color: #336474; /* Darker shade of blue on hover */
  cursor: pointer;
}

.book-text {
  font-size: 20px;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  color: rgb(254, 254, 255);
  margin-bottom: -15px;
}
/* Existing styles ... */

.book-now-link{
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 30px;
  background-color: #529FB7;
  color: white;
  text-decoration: none;
  /* transition: background-color 0.3s ease; */
  outline: none; /* Remove the default focus outline */
}

.mobile-only{
  display: none; 
}

.book-now-link:hover {
  background-color: #336474;
  transform: none; /* Disable any transformations on hover */
  cursor: pointer;
}

.card-img{
  width: 100%;
  height: 300px;
  border-radius: 9px;
  box-sizing: content-box; 
  
}
.card-title{
  margin-left: 25px;
  margin-right: auto;
  margin-bottom: 0px;
 
  font-size: 24px;
  color: #000000;
}
.booking-cards{

    display: flex;
    flex-direction: column;
    flex: 0 0 auto; 
    width: 400px;
    height: 518px;
    background-color: #FFFFFF;
    align-items: center;
    border-radius: 9px;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.5);
    margin-bottom: 20px;
}

.card-description{
  margin-top: 0px;
  margin-right: auto;
  max-width: 280px;
  font-size: 14px;
  text-align: left;
  color: #000000;
  margin-left: 25px;
}
.book-now-card{
  width: 141px;
  height: 45px;
  border-radius: 20px;
  box-sizing: border-box;
  border: 1px solid rgba(27, 31, 35, 0.15);
  box-shadow: rgba(27, 31, 35, 0.04) 0 1px 0, rgba(255, 255, 255, 0.25) 0 1px 0 inset;
  color: #FFFFFF;
  font-size: 16px;
  background-color: #012D61;
  margin-left: 0px;
  margin-right: 70px;
  margin-bottom: auto;
  
}

.card-price {
  margin-top: 0px;
  margin-bottom: 0px;
  margin-right: auto;
  font-weight: bold;
  font-size: 20px;
  color: #000000;
  margin-left: 25px;
}
.card-duration{
  margin-top: 0px;
  margin-right: auto;
  font-weight: semi-bold;
  font-size: 16px;
  color: #000000;
  margin-left: 25px;
}
.booking-info-card{
    
    display: flex;
    flex-wrap: nowrap;
    gap: 10px;
    
    padding: 0px;
    align-self: left;
    
    margin-bottom: 27px;
    align-items: center;

    
}
.booking-info-card > p:hover  {
  cursor: pointer;
  color: #336474;
}

.booking-info-card > .info-img-card :hover {
  cursor: pointer;
  filter: brightness(0.9); 
}
.card-info{
    font-size: 16px;
    font-weight: semi-bold;
    color: #529FB7;
    margin-left: 0px;
    margin-right: 0px;
    
}
.info-img-card{
  border-radius: 50%;
  background-color: #529FB7;
  display: flex;
  margin-left: 0px;
  height: 31px;
  width: 31px;
  transition: background-color 0.3s;
}

.card-footer {
  margin-right: auto;
}

.mobile{
  display: none;
}

.treatment-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* This will push the button down */
  align-items: center;
  width: 25vw;
  padding-bottom: 1rem;
  border-radius: 15px;
  background-color: white;
  box-shadow: 0px 0px 100px rgba(0, 0, 0, 0.2);
  color: black;
  height: 95%; 
}

.treatment-card img {
  height: 40%;
  object-fit: cover;
  width: 100%;
  border-radius: 15px 15px 0px 0px; 
}

.treatment-text {
  flex-grow: 1;
  margin-bottom: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
}

.treatment-name {
  font-weight: 400;
  font-size: 20px;
  text-align: center;
}

.treatment-info {
  font-weight: 400;
  font-size: 17px;
  text-align: center;
}

.button-stretch {
  align-self: stretch; /* This will make the button stretch to the container width */
  width: 100%; /* Remove inline style width and set to 100% of the parent container */
  margin-top: auto;
}

.contact-card {
  border-radius: 20px;
  background-color: white;
  box-shadow: 0px 0px 100px rgba(0, 0, 0, 0.2);
  width: 75vw;
  max-width: 1280px;
  color: black;
  display: flex;
  position: absolute; 
  z-index: 2; 
}

.contact-card h4 {
  font-weight: 500;
  font-size: 40px;
  line-height: 30px;
}

.inputs-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 30vw;
  max-width: 500px;
}

.styled-input {
  border: 2px solid #DFDFDF;
  border-radius: 20px;
  padding: 1rem;
  font-size: 18px;
  width: 100%;
  font-family: Poppins;
}

.large-input {
  width: 100%; 
  height: 7rem;
  resize: vertical;
  overflow: hidden;
  overflow-y: auto;
  scrollbar-width:thin ;
  scrollbar-color: #c0c0c0 transparent;
  scroll-behavior: smooth;
}

.large-input::-webkit-scrollbar{
  width: 8px;
}

.large-input::-webkit-scrollbar-track{
  background-color: transparent;
  border-radius: 20px;
}

.styled-input::placeholder {
  color: #ccc;
}

.styled-input:focus {
  border-color: #529FB7; 
}

.input-padding {
  padding: 1.5rem 0 1.5rem 5rem;
}

.horizontal-input {
  display: flex;
  gap: 1rem;
}

.full-w-input {
  width: 100%;
}

.contact-image {
  width: 30vw;
  max-width: 500px;
  margin-left: auto; 
  overflow: hidden;
  border-radius: 0 20px 20px 0;
}

.contact-image img {
  width: auto;
  height: 100%;
  border-radius: 0 20px 20px 0; 
}

.send-message {
  padding: 1rem 2rem;
  border-radius: 30px; 
  background-color: #2AA0C5; 
  color: white; 
  text-decoration: none; 
  transition: background-color 0.3s ease; 
  border: none;
  outline: none; 
  cursor: pointer; 
  text-align: center; 
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  font-size: 20px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.send-message:hover {
  background-color: #2588A7; /* Darker shade of blue on hover */
}

.send-message circle {
  background-color: #69B8D1;
}

.circle-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border-radius: 50%; 
  height: 65px;
  width: 65px;
  background-color: #2AA0C5;
  text-decoration: none; 
  cursor: pointer;
  border: none;
  outline: none;
  transition: background-color 0.3s ease;
}

.circle-button button {
  border: none;
  background: none;
  color: inherit;
  cursor: pointer;
  padding: 0;
  margin: 0;
  outline: none;
}

.circle-button:hover {
  background-color: #2588A7; /* Darker shade of blue on hover */
}

.thank-you-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 27.5vw;
  gap: 20px; /* Adjust the gap as needed */
  margin-left: auto;
  margin-right: auto;
}


.thank-you-logo {
  height: auto;
  width: 20%;
  max-width: 250px;
}

.thank-you-title {
  font-size: 28px;
  margin-bottom: 1rem;
}

.book-now-card:hover {
  background-color: #011b3a;
  text-decoration: none;
  transition-duration: 0.1s;
  cursor: pointer;
}

.book-now-card:disabled {
  background-color: #012D61;
  border-color: rgba(27, 31, 35, 0.15);
  color: #959DA5;
  cursor: default;
}

.book-now-card:active {
  background-color: #011b3a;
  box-shadow: rgba(225, 228, 232, 0.2) 0 1px 0 inset;
  transition: none 0s;
}

.book-now-card:focus {
  outline: 1px transparent;
}

.book-now-card:before {
  display: none;
}

.book-now-card:-webkit-details-marker {
  display: none;
}
.cards-list {
  display: flex;
  flex-wrap: nowrap;
  gap: 20px;
  overflow-x: auto;
  padding-left: 90px ;
  margin-top: -650px;
  margin-bottom: 20px;
  
}
.burger-menu-iphone{
  display: none;
}

.burger-menu {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 75vw;
  z-index: 999;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  box-shadow: -8px 0px 10px -2px rgba(0, 0, 0, 0.3); /* Adjust the spread and blur */
  margin: 0 0;
  padding: 0;
  border-color: black;
}

.burger-menu * {
  padding-bottom: 15px !important; /* Add padding to all elements inside .burger-menu */
  width: 100%;
}

.burger-menu a {
  margin-left: 10%;
  color: black;
  font-weight: bold;
}

.burger-menu a:hover{
  background-color: rgb(221, 220, 220) ;
}

.burger-menu button{
  align-self: center;
  justify-self: center;
  margin: 0 0 0 0;
}

.menu-mobile-only{
  display: none;
}
.menu-mobile-only-close{
  display: none;
}
.menu-book-now{
  display: inline-block; /* Allows width and height to be set */
  padding: 0.5rem 1.25rem;
  border-radius: 30px; /* Rounded corners */
  background-color: #e7eef000; /* A shade of blue */
  color: #2AA0C5; /* Text color */
  text-decoration: none; /* Removes underline */
  transition: background-color 0.3s ease; /* Smooth background color transition */
   /* NEW - remove default button border */
  outline: none; /* NEW - remove focus outline */
  cursor: pointer; /* NEW - ensure it's clear this is clickable */
  text-align: center; /* NEW - ensure text is centered */
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  font-size: 20px;
  width: 70%;
  border-color: #2AA0C5;
  border-width: 3px;
  align-items: center;
  justify-content: center;
}

.therapist-card {
  border-radius: 20px;
  background-color: white;
  padding: 5rem 7.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70%;
  gap: 4rem;
  z-index: -1;
}

.therapist-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.therapist-name {
  font-size: 40px;
  font-weight: 800;
  color: black;
  text-align: center;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
}

.therapist-title {
  font-size: 14px;
  font-weight: 400;
  color: black;
  text-align: center;
  margin-top: 0px;
  margin-bottom: 0px;
}

.therapist-description {
  font-weight: 400;
  font-size: 16px;
  text-align: center;
}

.therapist-image-wrapper {
  position: relative;
  display: inline-block; /* or 'block' depending on layout */
}

.therapist-image-wrapper::before,
.therapist-image-wrapper::after {
  z-index: -1;
  content: "";
  position: absolute;
  background: url('images/dots.png') no-repeat; /* path to your dots image */
  background-size: contain; /* or 'cover' depending on the desired appearance */
  width: 100px; /* set size */
  height: 100px;/* set size */
}

.therapist-image-wrapper::before {
  top: 0;
  left: 0;
  transform: translate(-35%, -25%); /* adjust as needed */
}

.therapist-image-wrapper::after {
  bottom: 0;
  right: 0;
  transform: translate(35%, 25%); /* adjust as needed */
}

.therapist-card img {
  display: block;
  width: 250px; /* set size */
  height: 250px; /* set size */
  border-radius: 20px; /* your existing border-radius */
  object-fit: cover; /* maintain aspect ratio */
  z-index: 1;
}

@media screen and (max-width: 1920px) {
  .home-description {
    padding-top: 3.5rem;
  }

}

@media screen and (max-width: 900px) {
  .input-padding {
    padding: 0.5rem 1rem;
  }

  .contact-card {
    border-radius: 0; /* Remove curved borders for mobile */
    flex-direction: column; /* Stack elements vertically */
    align-items: stretch; /* Stretch items to full width */
    justify-content: start;
    width: 100%; /* Adjust width to full viewport width */
    padding: 0rem; /* Adjust padding as needed */
    position: static; /* Adjust position if necessary */
    box-shadow: none;
    margin-bottom: 7rem;
  }

  .contact-card h4 {
    font-size: 30px;
    margin-bottom: 1rem;
  }

  .contact-image {
    display: none;
  }

  .inputs-container, .horizontal-input {
    width: 100%; /* Full width for input containers */
    max-width: none; /* Remove max-width constraints */
  }

  .send-message {
    width: auto
  }

  .thank-you-message {
    width: 80%;
  }

  .rectangle-fw {
    margin-bottom: 8vh;
    height: 66vh;
    padding-bottom: 5rem;
  }

  .rectangle-fw{
    height: 80% !important;
  }

  .mobile-height {
    height: 65vh !important;
    margin-bottom: 2rem !important;
  }

  .mobile-margin-botton {
    margin-bottom: 2rem !important;
  }

  .footer .horizontal-stretched{
      flex-direction: column;
  }

  .footer .vertical-center{
    align-items: center;
    width: 100%;
  }
  .testimonial-text, .testimonial-name {
    font-size: 15px;
  }

  .mobile{
    display: flex;
    margin-top: 11rem;
    height: 300px; /* Adjust height as needed */
    width: 100%; /* Ensure full width */
  }

  .cuppingImage{
    display: none;
  }

  .vertical-start .home-text{
    text-align: center;
    color: black;
    font-size: 29px;
    margin-top: 1rem;
  }

  .vertical-center-container {
    display: flex;
    align-items: center;
    justify-content: center;
    /* Additional styles to center vertically and horizontally */
  }

  .dots{
    width: 30%;
    height: auto;
    bottom: 47%;
    right: 8%;
  }

  .plus-imagetop{
    width: 21%;
    height: auto;
    bottom: 52%;
    right: 2%;
  }

  .plus-imagebot{
    width: 21%;
    height: auto;
    bottom: 46%;
    right: 2%;
  }

  .home-descriptions{
    font-size: medium;
    text-align: center;
    margin-bottom: -0.1rem;;
  }

  .group-dots{
    width: 40%;
    height: auto;
  }

  .desktop-only{
    display: none;
  }

  .mobile-only{
    display: inline;
  }
  
  .mobile-vertical{
    flex-direction: column;
  }

  .mobile-no-height{
    height: auto !important;
  }
  .vertical-center .mobile-only{
    align-items: center;
    justify-content: center;
  }
  .vertical-center-container .vertical-start {
    align-items: center;
  } 

  .home-text {
    font-size: 32px;
  }
  .horizontal.column{
    display: flex;
    flex-direction: column;
    align-items: center; /* Center items horizontally */
    position: relative;
  }
  
  .treatment-card {
    width: 90vw;
  }

  .book-now{
    width: 35vw !important;}
  .book-now-large{
    display:inline
  }
  .learn-more p{
    font-size: large;
  }

  .circle{
    height: 30px;
    width: 30px;
  }
  .circle img{
    height: 20px;
    width: 20px;
  }
  .home-text{
    font-size: 40px;
    text-align: center;
    margin-top: 1.4em;
  }
  .mobile-only .small-text{
    margin-top: 10vh;
  }
  .consult{
    margin-top: 1.5rem;
  }
  .sub-text{
    font-size: 15px;
    width: 70%;
  }
  
  .contact-card{
    width: 100% !important;
  }
  .faq-mobile{
    font-size: 25px !important;
  }
  .cupping-therapy-image{
    width: 80%;
    margin-top: 3rem;
  }
  .treatment-description{
    font-size: 13px;
    width: 80%;
    
    }
  
  .center.testimonial-title.cupping{
    font-size: 35px;
  }

  .rotate {
    transform: rotate(90deg);
    transition: transform 0.3s ease; /* Optional: Add transition for smoother effect */
  }

  .mobile-no-height {
    height: auto;
  }
  .burger-menu-iphone{

    width: 20%;
    display: block;
  }
  .menu-mobile-only{
    display: flex;
    fill: #2AA0C5;
    width: 27%;
    height: 4vh;

    
  }
  .menu-mobile-only-close{
    display: inline;
    fill: #2AA0C5;
    align-self: flex-end;
    margin-top: 4%;
    height: 4%;
    width: 30%;
  }
  .menu-mobile-only.clicked {
    fill: #1b657c; /* Change the fill color to red when clicked */
  }
  .menu-mobile-only-close.clicked {
    fill: #1b657c; /* Change the fill color to red when clicked */
  }
  
  .footer-header {
    margin-bottom: 0.5rem;
  }

  .logo.desktop-only {
    display: none;
  }

  .logo {
    width: 10%;
    margin-left: 0px;
    align-items: center;
    justify-content: center;
    display: flex;
  }
  .navbar{
    background-color: white;
    border-bottom: 1px solid #878585;
  }

  .therapist-card {
    border-radius: 15px;
    padding: 1rem;
    padding-top: 3rem;
    flex-direction: column;
    width: 80%;
    gap: 1.5rem;
    z-index: -1;
  }
  
  .therapist-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .therapist-name {
    font-size: 24px;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }
  
  .therapist-title {
    font-size: 10px;
  }
  
  .therapist-description {
    font-size: 12px;
  }
  
  .therapist-image-wrapper::before,
  .therapist-image-wrapper::after {
    width: 75px; /* set size */
    height: 75px;/* set size */
    z-index: -1;
  }
  
  .therapist-image-wrapper::before {
    top: 0;
    left: 0;
    transform: translate(-35%, -25%);
  }
  
  .therapist-image-wrapper::after {
    bottom: 0;
    right: 0;
    transform: translate(35%, 25%); /* adjust as needed */
  }
  
  .therapist-card img {
    width: 115px;
    height: 115px;
    border-radius: 15px;
    z-index: 1;
  }
}
@media screen and (min-width: 900px) and (max-width: 1440px) {
  /* Your CSS rules for this viewport range */
  /* .vertical-center-container.laptop{
    width: 20vw;
  } */
  .horizontal.laptop{
    height: 60vh;
    width: 75vw;
  }
  .plus-imagebot.laptop{
    width: 5vw; 
  }
  .cuppingImage.laptop{
    width: 35vw;
  }
  .home-text.laptop{
    font-size: 40px;
  }
  .rectangle-fw.laptop{
    height: 60vh;
  }
  .contact-card.laptop{
    height: 80vh;
  }
  .horizontal.card-laptop{
    height: 83vh !important;
  }
  
  .rectangle-fw.contact-laptop.blue.mobile-margin-botton{
    height: 100vh !important;
    margin-bottom: 75vh !important;
  }
}
